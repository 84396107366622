.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .modal-content {
      background: var(--backColor);
      margin: 25px;
      padding: 20px;
      border-radius: 5px;
      max-width: 1000px;
      width: 100%;
      height: 80%;
      overflow: hidden;
  
      .buttonDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
  
        > h1{
          font-weight: 700;
        }
        
      }
  
      .model-search-box {
        margin-top: 0.5%;
        display: flex;
        align-items: center;
        flex-direction: row;
        /* position: absolute; */
        top: 50px; /* Adjust the top position based on your design */
        right: 10px; /* Adjust the right position based on your design */
        margin-bottom: 2% ;
       height: 13%;
        background-color: linear-gradient(
          106.37deg,
          rgb(187, 103, 255) 0%, 
          rgb(196, 132, 243) 100%,
          rgb(255, 145, 157) 0%, rgb(252, 146, 157) 100%);
        border: 1px solid #ccc;
        border-radius:  0.7rem;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); /* Soft box shadow */
        z-index: 1000;
      }


      .formApp{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        gap: 15px;
        width: 100%;

        .formRow {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;
    
            .inputSection {
              display: flex;
              flex-direction: column;
              width: 100%;
    
                > input  {
                width: 100%;
                padding: 10px;
                border: 2px solid hsl(217, 91%, 60%, 30%);
                height: 3rem;
                border-radius: 4px;
                }
            }
            
        }

        .buttonRow {   
            display: flex;
            flex-direction: row;
            justify-content: start;
            gap: 10px;
            width: 80%;
    
            > button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              width: 200px;
              font-size: 17px;
              padding: 10px;
              border: none;
              cursor: pointer;
              white-space: nowrap;
    
                > svg {
                width: 20px;
                height: 20px;
                }
            }
            .button-add-product {
              background: var(--buttonAdd);
        
              &:hover, &:focus {
                background: var(--buttonAddHover)
              }
            }
        
            .button-back {
              background: var(--buttonBack);
              &:hover, &:focus {
                background: var(--buttonBackHover)
              }
            }
    
        }
    
        .table-container {
            height: 40vh;
            width: 100%;
            align-items: center;
            text-align: center;
            background-color: transparent;
    
            .heightSetting {
                max-height: 80%;
                background: var(--glass);

   
                }
        }  
    }


    }
    
    .modelTableContainer {
      margin-top: 5px;
      max-height: 70%;
        overflow: auto;
    }

    
  }
  
