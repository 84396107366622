.LoginContainerFBR {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(231, 231, 231);
    /* background-image: url("../../images/loginBack1.jpg"); */
    position: fixed;
    top: 0;
    left: 0;

     .imageSection {
        width: 65%;
        height: 100vh;

        > img {
            width: 100%;
            height: 100vh;
          }
          
      }

      .LoginSignUpBoxFBR {
        /* background-color: white; */
        width: 35%;
        height: 100vh;
        box-sizing: border-box;
        overflow: hidden;

        .LoginSignUp-ToggleFBR {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 15vmax;

          > img {
            margin: 30px;
            height: 70%;
          }

          > h3 {
            color: rgba(74, 125, 252, 0.678);
            font-family: 'Gill Sans';
            transition: all 0.5s;
            cursor: pointer;
            display: grid;
            place-items: center;
            width: 100%;

            &:hover {
              color: tomato;
            }
          }
        }

        .loginFormFBR,
        .signUpFormFBR {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10%;
            /* margin: auto; */
            padding: 2vmax;
            justify-content: space-evenly;
            height: 50%;
            transition: all 0.5s;

            > a {
                color: rgba(0, 0, 0, 0.651);
                padding-right: 15px;
                text-decoration: none;
                align-self: flex-end;
                transition: all 0.5s;
                font: 500 0.8vmax "Gill Sans";
              }

            > div {
                display: flex;
                width: 100%;
                align-items: center;

                > input {
                  padding: 1vmax 4vmax;
                  padding-right: 1vmax;
                  width: 100%;
                  box-sizing: border-box;
                  border: 1px solid rgba(0, 0, 0, 0.267);
                  border-radius: 4px;
                  font: 300 0.9vmax cursive;
                  outline: none;
                }
                

                > svg {
                  position: absolute;
                  transform: translateX(1vmax);
                  font-size: 1.6vmax;
                  // margin-bottom: 25px;
                  color: rgba(0, 0, 0, 0.623);
                }

                .visibilitySvg {
                  position: absolute;
                  transform: translateX(28vmax);
                  font-size: 1.6vmax;
                  // margin-bottom: 25px;
                  color: rgba(0, 0, 0, 0.623);
                }
              }

            .loginBtnFBR,
            .signUpBtnFBR {
                border: none;
                background-color: #3b8aea;
                color: white;
                font: 300 0.9vmax "Roboto";
                width: 90%;
                padding: 0.8vmax;
                cursor: pointer;
                transition: all 0.5s;
                margin-bottom: 0;
                border-radius: 10px;
                outline: none;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
            }
        }
      }
  }
  

  .LoginContainer {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(231, 231, 231);
    /* background-image: url("../../images/loginBack1.jpg"); */
    position: fixed;
    top: 0;
    left: 0;

    .LoginSignUpBox {
      background-color: white;
      width: 25vw;
      height: 70vh;
      box-sizing: border-box;
      overflow: hidden;
 
      .LoginSignUp-Toggle {
        display: flex;
        height: 3vmax;

        > p {
          color: rgba(0, 0, 0, 0.678);
          font: 300 1vmax "Roboto";
          transition: all 0.5s;
          cursor: pointer;
          display: grid;
          place-items: center;
          width: 100%;
        }
        
      }

    .loginForm {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      padding: 2vmax;
      justify-content: space-evenly;
      height: 70%;
      transition: all 0.5s;

     

      > a {
        color: rgba(0, 0, 0, 0.651);
        padding-right: 15px;
        text-decoration: none;
        align-self: flex-end;
        transition: all 0.5s;
        font: 500 0.8vmax "Gill Sans";
      }
      

      > div {
        display: flex;
        width: 100%;
        align-items: center;
        > input {
          padding: 1vmax 4vmax;
          padding-right: 1vmax;
          width: 100%;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.267);
          border-radius: 4px;
          font: 300 0.9vmax cursive;
          outline: none;
        }
        > svg {
          position: absolute;
          transform: translateX(1vmax);
          font-size: 1.6vmax;
          // margin-bottom: 25px;
          color: rgba(0, 0, 0, 0.623);
        }

        .visibilitySvg {
          position: absolute;
          transform: translateX(18vmax);
          font-size: 1.6vmax;
          // margin-bottom: 25px;
          color: rgba(0, 0, 0, 0.623);
        }
      }

      .loginBtn,
      .signUpBtn {
        border: none;
        background-color: tomato;
        color: white;
        font: 300 0.9vmax "Roboto";
        width: 90%;
        padding: 0.8vmax;
        cursor: pointer;
        transition: all 0.5s;
        margin-bottom: 0;
        border-radius: 10px;
        outline: none;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
      }
    }   }
  }
  