.ProductType.theme-white, .ProductType.theme-blue, .ProductType.theme-gradient {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 92vh;
    border-radius: 2rem;
  }


.secondContainer{
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin: 18vh 2vh 0 2vh;
    height: 95%;
    width: 100%;
    padding: 20px;
    background: var(--glass);
    .contentt-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;

        .heading-container {
            display: flex; /* Display h3 and h5 in a row */
            flex-direction: row; /* Align h3 and h5 vertically */

           > h3 {
                line-height: 1.3;
                margin: 0;
                font-size: 26px;
              }
              
            >h5 {
                line-height: 1.5;
                margin: 0;
                font-size: 20px;

                .total-records {
  
                    background: var(--lightPurple);
                    color: #ffffff; /* Add your desired text color here */
                    padding: 2px 5px; /* Add padding to create spacing around the background color */
                    margin-left: 10px;
                    border-radius: 4px; /* Optionally, add border radius for rounded corners */
                  }

                .rowCount {
                    background-color: white; 
                    /* background: var(--backColor); */
                    color: #222; /* Add your desired text color here */
                    padding: 2px 5px; /* Add padding to create spacing around the background color */
                    border-radius: 4px; /* Optionally, add border radius for rounded corners */
                  }
              }
          }

          .excelDiv {
            > button {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.75;
            letter-spacing: 0.02857em;
            text-transform: uppercase;
            max-width: 180px;
            margin-left: 10px;
            padding: 5px 15px 5px 15px;
            border: 1px solid rgb(119, 7, 7);
          color: rgb(119, 7, 7);
            justify-content: space-between;
            border-radius: 4px;
          }

          > span > svg {
            color: rgb(119, 7, 7);
          }
        
          }
      }

      .search-box {
        width: 100%;
        height: 15%;
        padding: 20px;
        margin: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        background: var(--backColor);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        // margin: auto;
    
        > input {
          width: 100%;
          padding: 10px;
          border: 2px solid hsl(217, 91%, 60%, 30%);
          height: 3rem;
          border-radius: 4px;
        }

        .dropdown  {
          width: 100%;
          padding: 10px;
          height: 3rem;
          border-radius: 4px;
        
      }
  
      //  > .saleProfitDatePicker, .rs-input-group.rs-input-group-inside {
      //   width: 100%;
      //   height: 3rem;
      //   border: 1px solid rgb(231, 231, 232);
      //  }
  
        button {
        background: var(--buttonAdd);
        align-items: center;
        width: 50%;
        height: fit-content;
        }
    }


    .table-container {
        height: 60vh;
        width: 100%;
        align-items: center;
        text-align: center;
        background-color: transparent;

        

        .heightSetting {
          max-height: 90%;
          background: var(--glass);

          .customTable {
            border-collapse: separate;
            // border-spacing: 0 10px; 
            width: 100%; 

            .TableRow {
              border-right: 1px solid #707070; /* Adjust the color as needed */
              padding: 10px; /* Adjust the padding as needed */
            }

            .TableCell {
              border-bottom: none;
            }

            .custom-table-pagination  {
              .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
                display: none;
              }

              .MuiTablePagination-input {
                display: none;
              }
            }
          }
        
        }
    }  

    .form{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: fit-content;
      gap: 15px;
      width: 100%;

      .buttonRow {   
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
        width: 25%;

        > button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 200px;
          font-size: 17px;
          padding: 10px;
          border: none;
          cursor: pointer;
          white-space: nowrap;

          > svg {
            width: 20px;
            height: 20px;
          }
        }
        .button-add-product {
          background: var(--buttonAdd);
    
          &:hover, &:focus {
            background: var(--buttonAddHover)
          }
        }
    
        .button-back {
          background: var(--buttonBack);
          &:hover, &:focus {
            background: var(--buttonBackHover)
          }
        }

      }

      .formRow {
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 80%;

        .inputSection {
          display: flex;
          flex-direction: column;
          width: 100%;

          > input  {
            width: 100%;
            padding: 10px;
            border: 2px solid hsl(217, 91%, 60%, 30%);
            height: 3rem;
            border-radius: 4px;
          }
        }
        
      }

      .table-container {
        height: 40vh;
        width: 100%;
        align-items: center;
        text-align: center;
        background-color: transparent;
        
        .heightSetting {
          max-height: 80%;
          background: var(--glass);
        }
    }  
    }

      
    
  } 
 