.customerVisaRecordTable {
      margin-top: 77px;
      margin-left: 1%;
      align-items: center;
      width: 98%;
      height: 87%;
      background: var(--glass);
      border-radius: 2rem;
  
      .customerRecordVisa {
        height: 100%;
        width: 100%;
        padding: 40px;
      }
  
      .customerVisaInputDiv {
        display: flex;
        flex-direction: row;
        margin-top: 5%;
      }
  
      .customerVisaInputField {
        margin-left: 2%;
        padding: 10px;
        border-radius: 2px;
        border: 1px solid var(--borderColor);
        width: 25%;
  
        &:focus {
          outline: none;
          border-radius: 2px;
          border: 1px solid var(--borderColor);
        }
      }
  
      .customerVisaUpdateButton {
        margin-left: 2%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        width: 15%;
        background: var(--viewTable);
  
        &:hover {
          background: var(--viewTable);
        }
      }
  
      .custom-dropdown {
        position: relative;
        display: inline-block;
        background-color: white;
        margin-left: 2%;
        width: 30%;
        border-radius: 2px;
  
        &:focus {
          border-radius: 2px;
          border: 1px solid var(--borderColor);
        }
  
        .selected-option {
          padding: 10px;
          border: 1px solid #ccc;
          cursor: pointer;
          background-color: white;
          border-radius: 2px;
          border: 1px solid var(--borderColor);
        }
  
        .options {
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 1000;
          background-color: #fff;
          border: 1px solid #ccc;
          border-top: none;
          width: 100%;
          max-height: 150px;
          overflow: auto;
          border-radius: 2px;
          border: 1px solid var(--borderColor);
        }
  
        .option {
          padding: 10px;
          cursor: pointer;
          color: black;
  
          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
  
      .previewDropdownDiv {
        display: flex;
        flex-direction: row;
        max-width: 50%;
        height: 8%;
  
        > button {
          margin-left: 2%;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          width: 15%;
          background: var(--viewTable);
  
          &:hover {
            background: var(--viewTable);
          }
        }
      }
  
      .heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1%;
  
        button {
          width: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          height: 30px;
          background: var(--viewTable);
  
          &:hover {
            background: var(--viewTable);
          }
        }
      }
  
      .searchCustomerRecordVisa-withouInputfields-box {
        height: 20%;
        width: 100%;
        background: var(--navBlue);
        border-radius: 5px;
      }
  
      .searchButton {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2.5%;
        margin-right: 1%;
        height: 40px;
        border-radius: 10px;
        background: transparent;
        width: 30%;
  
        button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 1%;
          border-radius: 5px;
          height: 100%;
          background: var(--viewTable);
        }
      }
  
      .previewCustomerRecordVisa-Inputfields-box {
        height: 15%;
        width: 100%;
        padding: 1%;
        margin-top: 1%;
        background: var(--navBlue);
        border-radius: 5px;
      }
  
      .customerRecordVisaTableFirstInputDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
      }
  
      .customerRecordVisaTableInputField {
        margin-top: 2.5%;
        margin-left: 1%;
        padding: 10px;
        border-radius: 2px;
        border: 1px solid var(--borderColor);
        width: 30%;
  
        &:focus {
          outline: none;
          border-radius: 2px;
          border: 1px solid var(--borderColor);
        }
      }
  
      .customerVisaTable-container {
        margin-top: 1%;
        height: 60%;
      }
  
      .previewTable {
        height: 77%;
        width: 100%;
        margin-top: 1%;
        background: var(--glass);
        padding: 10px;
        gap: 16px;
        margin-bottom: 1%;
        grid-template-columns: 11rem auto 20rem;
        overflow: hidden;
  
        > button {
          margin-left: 2%;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          width: 15%;
          background: var(--viewTable);
  
          &:hover {
            background: var(--viewTable);
          }
        }
      }
  
      .printSection {
        display: none;
      }
  
      .InvoiceLaserFirstRecordDiv,
      .InvoiceSecondCont,
      .InvoiceThirdCont,
      .consolidateCustomerLaserdivs,
      .consolidateCustomerSecondLaserdivs,
      .consolidateCustomerLaserInvoiceNumber {
        display: flex !important;
        white-space: nowrap;
      }
  
      .InvoiceLaserFirstRecordDiv,
      .InvoiceSecondCont {
        justify-content: space-between;
        width: 50%;
      }
  
      .InvoiceThirdCont {
        flex-direction: column;
      }
  
      .printPagePrev {
        padding: 10px;
        padding-bottom: 0px;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
        height: 90%;
        margin: 20px;
        overflow: auto;
      }
  
      .headerHeading, .invoiceAddress, .invoiceTitle, .invoicePhoneNo {
        display: flex;
        justify-content: center;
        font-weight: bold;
        margin-top: -15px;
        text-align: center;
        margin-bottom: -5px;
  
        h4 {
          font-weight: bold;
        }
      }
    
  }
  