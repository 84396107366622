.Setting.theme-white,.Setting.theme-blue, .Setting.theme-gradient  {
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 12vh 12px 12px 5px;
    width: 100%;
    height: 92vh;
    border-radius: 2rem;
}

  .settingGlass {
    display: flex !important;
    align-items: center;
    margin: 18vh 2vh 0 2vh;

    // padding: 20px;
    // background: var(--glass);

    height: 95%;
    width: 100%;
    background: var(--glass);
    border-radius: 2rem;
    gap: 16px;
    grid-template-columns: 11rem auto 20rem;
    overflow: hidden;
  
  .settingSidebar {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 17%;
    padding-top: 6rem;
    transition: all 300ms ease;
    
   }
    .logoSettings {
        display: flex;
        height: 5rem;
        font-weight: bold;
        font-size: 22px;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        margin-left: 10%;
        height: 4%;
      }

      .settingMenu {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 50%;

        .menuItem {
            display: flex;
            align-items: center;
            gap: 1rem;
            height: 2.5rem;
            margin-left: 2rem;
            position: relative;
            transition: all 300ms ease;
            border-radius: 0.7rem;
            font-size: 14px;

            > span {
              white-space: nowrap;
            }

            &:hover {
                cursor: pointer;
              }
          }
      }
  }