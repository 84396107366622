.headerHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 40;
    font-weight: bold;
    text-align: center;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;

    .invoiceTitle {
        font-size: 45;
        font-weight: bold;margin: 0;
        font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
        // margin-top: "-15px";margin: 0;
        text-align: center;
        // margin-bottom: "-5px";
      }
      .invoiceAddress {
        font-size: 40;
        font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-weight: bold;margin: 0;
        text-align: center;
      }
      
      .invoicePhoneNo {
        display: "flex";
        justify-content: "center";
        font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: "40";
        flex-direction: row;margin: 0;
        text-align: center;
      }
  }

  .lasePrinter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family:  Roboto, sans-serif;
    width: 60vh;
    .InvoiceLaserFirstRecord {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family:  Roboto, sans-serif;
      .consolidateLaserColumn {
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        font-family:  Roboto, sans-serif;

        .consolidateLaserQuantity {
          display: flex;
          flex-direction: row;
          font-family:  Roboto, sans-serif;

          > h5 {
            margin: 0;
          }

          > p {
            margin: 0;
          }
        }
      }
    }
  }

  .mainDiv {
    width: 80%;
    display: flex;
    flex-direction: column;

    .DatesFirstDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 40;
        font-weight: bold;
        text-align: center;
        font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
        width: 100%;
    
        .firstDiv {
            display: flex;
            flex-direction: row;
            // margin-left: 30px;
            white-space: nowrap;
            width: 50%;
          }
          
          .secondDiv {
            display: flex;
            flex-direction: row;
            // margin-left: 30px;
            width: 50%;
            white-space: nowrap;
          }
      }
  }


  .printTable{
    width: 100%;
    overflow-y: auto;
    > div {
      > table {
        > tbody {
            > tr {
              font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
              align-items: center;
              text-align: center;
            }
        }
    }
    }
  }

  .bottomGrand {
    width: 100%;

    > h2 {
      font-weight: 1000;
      display: flex;
      flex-direction: row;
      place-content: end;
      font-size: small;
    }
  }
  

  